.heading {
  margin-right: auto;
  margin-left: auto;
  font-family: Barlow, sans-serif;
  font-size: 48px;
  text-align: center;
  color: black;
}

.body {
  height: 1500px;
  margin-top: 100px;
  border-style: solid;
  border-width: 0px;
  border-color: #191919;
  font-family: Barlow, sans-serif;
  line-height: 10px;
}

.titlebox {
  height: 100px;
  margin-top: 100px;
  margin-right: auto;
  margin-left: auto;
}

.paragraph {
  font-family: Barlow, sans-serif;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
}

.subtitle {
  margin: 20px auto 0px;
  font-family: Barlow, sans-serif;
  font-size: 16px;
  line-height: 40px;
  font-weight: 700;
  text-align: center;
  color: black;
}

.sub01 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;

  margin-right: auto;
  margin-left: auto;
}

.rect {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 0px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 100px;
  padding-left: 100px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  border-style: solid none none;
  border-width: 2px 4px 4px;
  border-color: #000;
}

.rect.scroll {
  border-style: none;
  border-width: 8px;
  border-color: #191919;
  border-radius: 20px;
  background-color: #191919;
}

.set01 {
  display: block;
  width: 32%;
  margin-right: auto;
  margin-left: auto;
}

.set01-a {}

.set-a-input {
  background-color: #191919;
}

.paragraph-2 {
  margin-bottom: 0px;
  padding: 25px 15px;
  color: #f1f2f2;
  font-size: 16px;
}

.paragraph-2.setb {
  padding-top: 15px;
  padding-bottom: 15px;
}

.set-a-title {
  padding-right: 15px;
  padding-left: 15px;
  font-family: Barlow, sans-serif;
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  color: black;
}

.sub02 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.scroll-circle {
  margin-top: 2px;
  margin-bottom: 2px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-style: solid;
  border-width: 8px;
  border-color: #f1f2f2;
  border-radius: 20px;
}

.sub03 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.set03 {
  display: block;
  width: 45%;
  margin-right: auto;
  margin-left: auto;
}

.sub04 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.set-b-input {
  height: 110px;
  padding-bottom: 0px;
  background-color: #191919;
}

.setb-field {
  width: 32%;
  height: 50px;
  margin-right: auto;
  margin-left: auto;
  background-color: #f1f2f2;
}

.paragraph-3 {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 10px;
}