.calculator {
 
  margin-left: 10%;
  margin-top: 7vh;
  width: 80%;
  position: relative;
  color: #2f1608;
  
  
  
  /* filter: blur(5px); */
}
.div_input input{
  font-size: 1em;
  color: #2f1608;
   
}

.calc_head {
  padding-top: 1em;
  color: #2f1608;
  font-size: 1em;
}

.calc_head h4 {
  font-size: 1.1em;
  color: #2f1608;
  margin: 0.2em 1em;
}

.calc_head h5 {
  color: #2f1608;
  font-size: 1em;
  margin: 0.2em 1em;
}

.calc_block {
  margin: 1em 1em 0;
  margin-top: 1em;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 1em;
}

.calc_inner1 {
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  flex: 1 25%;
  font-weight: 400;
  border: 1px solid #2f1608;
  background-color: #aaaaaa; 
  border-radius: 10px;
}

.calc_inner1 h4 {
  font-size: 1em;
  color: #2f1608;
  margin: 0.2em 1em;
}

.right_block {
  margin-left: 1.2em;
}

.calc_inner1 h5 {
  color: #2f1608;
  font-size: 1.3em;
  margin: 0.2em 1em;
}

.calc_input{
    margin: 1em 1em 0;
    display: flex;
    background-color: white  ;
}

.div_input{
    flex: 50%;
}

.div_input h5{
    font-size: 1em;
    font-weight: 500;
    margin: 0.4em 0.5em;
}

.div_input input{
    padding: 1em;
    width: 100%;
    border: none;
    background-color: transparent;
}

::placeholder{
    padding: 1em;
    font-size: 1.2em;
}
::placeholder:focus{
    outline: none;
}

.input{
    background-color: hsla(0,0%,100%,.2);
    border: 1px solid hsla(0,0%,100%,.2);
    border-radius: 10px;
    display: flex;
    align-items: center;
}

.input p{
    font-size: 1.1em;
    font-weight: 600;
    margin: 0 0.5em;
    cursor: pointer;
}

.calc_range{
    margin: 1.3em 1.3em;
}
.calc_range h5{
    margin: 1em;
    font-size: 1.1em;
}
.calc_range input{
    display: flex;
    align-self: center;
    width: 100%;
}

.calc_foot{
    margin: 1em 1em 0;
}

.foot_inner{
    display: flex;
    justify-content: space-between;
    font-size: 1em;
}
.foot_inner h4{
    font-weight: 500;
    margin: 1em;
}
.foot_inner h5{
    font-weight: 500;
    margin: 1em;
}
 


@media (min-width: 315px) and (max-width: 768px){
  .calculator {
    margin: 5vh auto;
    width: 90%;
    position: relative;
    color: #2f1608;
  }

  .calc_block{
    flex-direction: column;
  }

  .calc_input{
    margin-top: 0;
    flex-direction: column;
    background-color: white
  }

  .div_input{
    margin-top: 0.5em;
  }

  .calc_inner1 {
  margin-top: 1em;

}

.calc_inner1 h4 {
  font-size: 1em;
  color: #2f1608;
  margin: 0.2em 1em;
}

.right_block {
  margin-left:0;
}

.calc_inner1 h5 {
  color: #2f1608;
  font-size: 1.3em;
  margin: 0.2em 1em;
}
}